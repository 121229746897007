




import { Vue, Component } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "Eğitim / Yönetim Konsolu"
  }
})
export default class AdminCourseDashboard extends Vue {}
